import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="404" lang={locale} />
    <h1>404</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

NotFoundPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default NotFoundPage
